import React, { FC, useState } from 'react';
import AccountForm from '../../Form/AccountForm';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { Account } from '../../../interface/account';
import { accountApi } from '../../../api/accountApi';
import { tradCreationAccount, tradToasterAccount } from './AccountKeyTranslation';
import toast from 'react-hot-toast';

interface AccountFormProps {
  show: boolean;
  setShow: Function;
  refreshData: boolean;
  setRefreshData: Function;
}

const CreateAccountModal: FC<AccountFormProps> = (props) => {
  const { tradToasterAccountSuccessCreate } = tradToasterAccount();
  const { tradCreationAccountValidation, tradCreationAccountTitle, tradCreationAccountCancel } = tradCreationAccount();

  const { show, setShow, refreshData, setRefreshData } = props;
  const [account, setAccount] = useState<Account>({
    id: 0,
    name: '',
    principalContact: '',
    principalEmail: '',
    creationDate: '',
    creationLocaleDate: ''
  });

  const onNameChanged = (event) => {
    const newAccount = Object.assign({}, account);
    newAccount.name = event.target.value;
    setAccount(newAccount);
  };

  const onContactPrincipalChanged = (event) => {
    const newAccount = Object.assign({}, account);
    newAccount.principalContact = event.target.value;
    setAccount(newAccount);
  };

  const onEmailPrincipalChanged = (event) => {
    const newAccount = Object.assign({}, account);
    newAccount.principalEmail = event.target.value;
    setAccount(newAccount);
  };

  const onSubmiteForm = async (account) => {
    const result = await accountApi.create(account);
    if (result?.status === 200) {
      toast.success(tradToasterAccountSuccessCreate);
      setRefreshData(!refreshData);
      setShow(false);
    }
    setAccount({
      id: 0,
      name: '',
      principalContact: '',
      principalEmail: '',
      creationDate: '',
      creationLocaleDate: ''
    });
  };

  return (
    <CustomModal
      show={show}
      setShow={setShow}
      onAction={() => {
        onSubmiteForm(account);
      }}
      actionName={tradCreationAccountValidation}
      title={tradCreationAccountTitle}
      type="primary"
      cancelButton={tradCreationAccountCancel}>
      <AccountForm
        account={account}
        onChangeActionName={(event) => {
          onNameChanged(event);
        }}
        onContactPrincipalChanged={(event) => {
          onContactPrincipalChanged(event);
        }}
        onEmailPrincipalChanged={(event) => {
          onEmailPrincipalChanged(event);
        }}
      />
    </CustomModal>
  );
};
export default CreateAccountModal;
