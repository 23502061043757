import React, { FC } from 'react';
import { accountApi } from '../../../api/accountApi';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { Account } from '../../../interface/account';
import { tradDeleteAccount, tradToasterAccount } from './AccountKeyTranslation';
import toast from 'react-hot-toast';

interface AccountFormProps {
  show: boolean;
  setShow: Function;
  account: Account;
  refreshData: boolean;
  setRefreshData: Function;
}

const DeleteAccountModal: FC<AccountFormProps> = (props) => {
  const { tradToasterAccountSuccessDelete } = tradToasterAccount();
  const { tradDeleteAccountCancel, tradDeleteAccountTitle, tradDeleteAccountConfirmation, tradDeleteAccountMessage } =
    tradDeleteAccount();

  const { show, setShow, account, refreshData, setRefreshData } = props;

  const deleteAccount = async () => {
    const result = await accountApi.delete(account);
    if (result?.status === 204) {
      toast.success(tradToasterAccountSuccessDelete);
      setRefreshData(!refreshData);
    }
    setShow(false);
  };
  return (
    <CustomModal
      show={show}
      setShow={setShow}
      onAction={deleteAccount}
      cancelButton={tradDeleteAccountCancel}
      actionName={tradDeleteAccountConfirmation}
      type="danger"
      title={tradDeleteAccountTitle}>
      {tradDeleteAccountMessage}
    </CustomModal>
  );
};

export default DeleteAccountModal;
