import React, { FC, useEffect, useRef, useState, MutableRefObject } from 'react';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'react-bootstrap';
import { Role, Users } from '../../../interface/users';
import { userApi } from '../../../api/userApi';
import { rolesApi } from '../../../api/rolesApi';
import { Instance } from '../../../models/instance';
import { useParams } from 'react-router-dom';
import { MultiValue } from 'react-select';
import { tradEdition, tradToaster } from './UserKeyTranslation';
import toast from 'react-hot-toast';

interface UsersModalsProps {
  show: boolean;
  setShow: Function;
  currentUser: Users;
  setReloadData: Function;
  reloadData: boolean;
  originalInstance: Instance;
}

interface Option {
  readonly label: string;
  readonly value: string;
}

const options: Array<Option> = [];

const UserUpdateModal: FC<UsersModalsProps> = (props) => {
  const { show, setShow, setReloadData, reloadData, originalInstance, currentUser } = props;
  const nameUserRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const emailUserRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const rolesUserRef: any = useRef(null);

  const { tradToasterRolesCreate, tradToasterEmptyField } = tradToaster();
  const {
    tradEditionName,
    tradEditionEmail,
    tradEditionRole,
    tradEditionSelect,
    tradEditionRoleCreate,
    tradEditionTitle,
    tradEditionCancel,
    tradEditionSuccess
  } = tradEdition();
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<Array<Role>>([]);
  const [roleValue, setRoleValue] = useState<MultiValue<Option> | Option | null | undefined | Array<Role | Option>>(
    currentUser.roles
  );

  const { instanceId } = useParams();

  const createOption = (label: string, value: string) => ({
    label,
    value
  });

  const handleChange = (newValue: any) => {
    setRoleValue(newValue);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      const result = await rolesApi.getAll(instanceId);
      setRoles(result);
    };
    fetchRoles();
  }, [reloadData]);

  useEffect(() => {
    if (roles) {
      roles.forEach((role) => {
        if (options.find((option) => option.label === role.name)) {
          options.splice(0, options.length);
        }
        return options.push(createOption(role.name, role.id));
      });
    }
  }, [roles]);

  useEffect(() => {
    setRoleValue(currentUser.roles);
  }, [currentUser]);

  const handleCreate = async (inputValue: string) => {
    setLoading(true);
    const result = await rolesApi.create(originalInstance.instanceId, inputValue);
    if (result.status === 200) {
      toast.success(tradToasterRolesCreate);
      if (Array.isArray(roleValue)) {
        setRoleValue([...currentUser.roles, createOption(result.data.name, result.data.id)]);
      }
    }
    setReloadData(!reloadData);
    setLoading(false);
  };

  const submitForm = async () => {
    const nameValue = nameUserRef.current?.value;
    const emailValue = emailUserRef.current?.value;

    if (!nameValue || !emailValue) {
      toast.error(tradToasterEmptyField);
      return;
    }
    if (nameUserRef.current?.value && emailUserRef.current?.value) {
      const arrayRole: Array<Role> = [];
      rolesUserRef.current.props.value.map((role: { value: string; label: string }) => {
        return arrayRole.push({
          id: role.value,
          name: role.label
        });
      });
      const result = await userApi.update(originalInstance.instanceId, {
        id: currentUser.id,
        name: nameUserRef.current?.value,
        email: emailUserRef.current?.value,
        roles: arrayRole
      });
      if (result?.status === 200 || result?.status === 204) {
        toast.success(tradEditionSuccess);
        setShow(false);
      }
      setReloadData(!reloadData);
    }
  };

  const handleEmailChange = (e) => {
    if (emailUserRef.current) {
      emailUserRef.current.value = e.target.value.trim();
    }
  };

  return (
    <>
      <CustomModal
        show={show}
        setShow={setShow}
        onAction={() => submitForm()}
        actionName="Validation"
        title={tradEditionTitle}
        type={'primary'}
        cancelButton={tradEditionCancel}>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{tradEditionName}</Form.Label>
            <Form.Control type="text" defaultValue={currentUser.name} ref={nameUserRef}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradEditionEmail}</Form.Label>
            <Form.Control
              type="text"
              defaultValue={currentUser.email}
              ref={emailUserRef}
              onChange={handleEmailChange}></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>{tradEditionRole}</Form.Label>
            <CreatableSelect
              ref={(ref) => {
                rolesUserRef.current = ref;
              }}
              onChange={handleChange}
              formatCreateLabel={(inputValue: string) => `${tradEditionRoleCreate} ${inputValue}`}
              placeholder={tradEditionSelect}
              onCreateOption={handleCreate}
              isClearable
              options={options}
              isLoading={loading}
              isMulti
              value={roleValue}
            />
          </Form.Group>
        </Form>
      </CustomModal>
    </>
  );
};

export default UserUpdateModal;
