/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../i18n/config';

export function tradInstancedetailsToaster() {
  const tradInstancedetailsToasterResendSuccess = translatedText('user.resendUser.success', t);
  const tradInstancedetailsToasterResetSuccess = translatedText('user.resetPassword.success', t);
  return { tradInstancedetailsToasterResendSuccess, tradInstancedetailsToasterResetSuccess };
}

export function tradInstanceDetails() {
  const tradInstanceDetailsName = translatedText('user.name', t);
  const tradInstanceDetailsEmail = translatedText('user.email', t);
  const tradInstanceDetailsRole = translatedText('user.role', t);
  const tradInstanceDetailsLabel = translatedText('actions.label', t);
  const tradInstanceDetailsUpdate = translatedText('user.update.action', t);
  const tradInstanceDetailsDelete = translatedText('user.delete.action', t);
  const tradInstanceDetailsResend = translatedText('user.resendUser.action', t);
  const tradInstanceDetailsReset = translatedText('user.resetPassword.action', t);
  return {
    tradInstanceDetailsName,
    tradInstanceDetailsEmail,
    tradInstanceDetailsRole,
    tradInstanceDetailsLabel,
    tradInstanceDetailsUpdate,
    tradInstanceDetailsDelete,
    tradInstanceDetailsResend,
    tradInstanceDetailsReset
  };
}

export function tradInstanceDetailsTable() {
  const tradInstanceDetailsTableTitle = translatedText('user.title', t);
  const tradInstanceDetailsTableIdentityDelegation = translatedText('delegation.identity_delegation', t);
  const tradInstanceDetailsTableAddUser = translatedText('user.add_new_user', t);
  const tradInstanceDetailsTableFormat = translatedText('date.format', t);
  const tradInstanceDetailsTableSortPage = translatedText('sort_per_page', t);
  const tradInstanceDetailsTableSearch = translatedText('placeholder.search', t);
  return {
    tradInstanceDetailsTableTitle,
    tradInstanceDetailsTableIdentityDelegation,
    tradInstanceDetailsTableAddUser,
    tradInstanceDetailsTableFormat,
    tradInstanceDetailsTableSortPage,
    tradInstanceDetailsTableSearch
  };
}
