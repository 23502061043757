import React, { FC } from 'react';
import './App.scss';
import '../../i18n/config';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import CorporateLayout from '../CorporateLayout/CorporateLayout';
import Account from '../Account/Account';
import AuthenticationCallback from '../AuthenticationCallback/AuthenticationCallback';
import { Toaster } from '../../components/Toaster/Toaster';
import License from '../License/License';
import Instances from '../Instances/Instances';
import InstanceDetails from '../Instances/InstanceDetails';
import Dashboard from '../Dashboard/Dashboard';
import Error403 from '../../components/Errors/Error403/Error403';
import Error404 from '../../components/Errors/Error404/Error404';
import { LogoutCallback } from '../LogoutCallback/LogoutCallback';

const App: FC = () => {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/forbidden" element={<Error403 />} />
        <Route path="/" element={<CorporateLayout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="account" element={<Account />} />
          <Route path="license" element={<License />} />
          <Route path="instances" element={<Instances />} />
          <Route path="instances/:instanceId" element={<InstanceDetails />} />
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/authentication/callback" element={<AuthenticationCallback />} />
        <Route path="/logout-callback" element={<LogoutCallback />} />
      </Routes>
    </>
  );
};
export default App;
