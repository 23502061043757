import React, { FC } from 'react';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { instanceApi } from '../../../api/instanceApi';
import { Instance } from '../../../models/instance';
import { tradToaster, tradinstanceDelete } from './InstanceKeyTranslation';
import toast from 'react-hot-toast';

interface FormProps {
  show: boolean;
  setShow: Function;
  currentInstance: Instance;
  refreshInstance: Function;
}

const DeleteInstanceModal: FC<FormProps> = (props) => {
  const { tradToasterDeleteSuccess } = tradToaster();
  const { tradinstanceDeleteCancel, tradinstanceDeleteConfirm, tradinstanceDeleteTitle, tradinstanceDeleteMessage } =
    tradinstanceDelete();

  const { show, setShow, currentInstance, refreshInstance } = props;

  const deleteInstance = async () => {
    const deleteInstance = await instanceApi.delete(currentInstance.instanceId);
    if (deleteInstance?.status === 204) {
      toast.success(tradToasterDeleteSuccess);
      refreshInstance();
    }
    setShow(false);
  };
  return (
    <CustomModal
      show={show}
      setShow={setShow}
      onAction={deleteInstance}
      cancelButton={tradinstanceDeleteCancel}
      actionName={tradinstanceDeleteConfirm}
      type="danger"
      title={tradinstanceDeleteTitle}>
      {tradinstanceDeleteMessage}
    </CustomModal>
  );
};

export default DeleteInstanceModal;
