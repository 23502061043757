import React, { FC, useState } from 'react';
import { Button, ButtonGroup, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Environment, Instance } from '../../models/instance';
import { instanceApi } from '../../api/instanceApi';
import { stereoApplicationApi } from '../../api/stereoApplicationApi';
import { StereoApplication } from '../../models/stereoApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import useCopyToClipboard from '../../hooks/CopyToClipboard';
import { tradInstanceButton, tradInstanceLabel, tradInstanceToaster, tradInstanceUrl } from './CardKeyTranslation';
import toast from 'react-hot-toast';

interface InstanceCardProps {
  currentInstance: Instance;
  originalInstance: Instance | undefined;
  setCurrentInstance: Function;
  reloadData: boolean;
  setReloadData: Function;
  application: Array<StereoApplication>;
}

const InstanceCard: FC<InstanceCardProps> = (props) => {
  const { tradInstanceToasterUpdate, tradInstanceToasterCreate, tradInstanceToasterDelete, tradInstanceToasterinstanceUpdate } =
    tradInstanceToaster();
  const {
    tradInstanceLabelInfo,
    tradInstanceLabelInstanceToken,
    tradInstanceLabelName,
    tradInstanceLabelType,
    tradInstanceLabelState,
    tradInstanceLabelEnvironment,
    tradInstanceLabelComments
  } = tradInstanceLabel();
  const {
    tradInstanceUrlTeiaCore,
    tradInstanceUrlTeiaTwin,
    tradInstanceUrlTeiaEngine,
    tradInstanceUrlTeiaConnector,
    tradInstanceUrlTeiaWorkflow
  } = tradInstanceUrl();
  const {
    tradInstanceButtonSaas,
    tradInstanceButtonOnPremise,
    tradInstanceButtonStart,
    tradInstanceButtonStop,
    tradInstanceButtonArchived,
    tradInstanceButtonDemo,
    tradInstanceButtonIntegration,
    tradInstanceButtonPreProd,
    tradInstanceButtonProd,
    tradInstanceButtonCancel,
    tradInstanceButtonSave
  } = tradInstanceButton();
  const { currentInstance, setCurrentInstance, originalInstance, setReloadData, reloadData, application } = props;
  const [copy] = useCopyToClipboard();
  const [update, setUpdate] = useState<boolean>(false);
  const [key, setKey] = useState<number>(1);

  const onChangeInstanceName = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.name = event?.target.value;
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const onChangeInstanceType = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.type = event?.target.value;
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const onChangeInstanceState = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.state = event?.target.value;
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const onChangeEnvironment = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.environment = event?.target.value;
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const onChangeTeiaCoreUrl = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    let teia = newInstance.applications.find((app) => app.type === 1);
    if (teia) {
      teia.url = event.target.value;
    } else {
      teia = new StereoApplication();
      teia.url = event.target.value;
      teia.instanceId = newInstance.instanceId;
      teia.type = 1;
      newInstance.applications.push(teia);
    }
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const onChangeDigitalTwinUrl = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    let digitalTwin = newInstance.applications.find((app) => app.type === 2);
    if (digitalTwin) {
      digitalTwin.url = event.target.value;
      setCurrentInstance(newInstance);
    } else {
      digitalTwin = new StereoApplication();
      digitalTwin.url = event.target.value;
      digitalTwin.instanceId = newInstance.instanceId;
      digitalTwin.type = 2;
      newInstance.applications.push(digitalTwin);
    }
    setUpdate(true);
  };

  const onChangeTeiaEngineUrl = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    let teiaEngine = newInstance.applications.find((app) => app.type === 3);
    if (teiaEngine) {
      teiaEngine.url = event.target.value;
    } else {
      teiaEngine = new StereoApplication();
      teiaEngine.url = event.target.value;
      teiaEngine.instanceId = newInstance.instanceId;
      teiaEngine.type = 3;
      newInstance.applications.push(teiaEngine);
    }
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const onChangeTeiaConnectorUrl = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    let teiaConnector = newInstance.applications.find((app) => app.type === 4);
    if (teiaConnector) {
      teiaConnector.url = event.target.value;
    } else {
      teiaConnector = new StereoApplication();
      teiaConnector.url = event.target.value;
      teiaConnector.instanceId = newInstance.instanceId;
      teiaConnector.type = 4;
      newInstance.applications.push(teiaConnector);
    }
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const onChangeTeiaWorkflowUrl = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    let teiaWorkflow = newInstance.applications.find((app) => app.type === 5);
    if (teiaWorkflow) {
      teiaWorkflow.url = event.target.value;
    } else {
      teiaWorkflow = new StereoApplication();
      teiaWorkflow.url = event.target.value;
      teiaWorkflow.instanceId = newInstance.instanceId;
      teiaWorkflow.type = 5;
      newInstance.applications.push(teiaWorkflow);
    }
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const onChangeComment = (event) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.comment = event?.target.value;
    setCurrentInstance(newInstance);
    setUpdate(true);
  };

  const updateApplication = async (currentApplication: StereoApplication, originalApplication: StereoApplication) => {
    if (currentApplication?.url !== '') {
      if (originalApplication?.url !== '' && currentApplication?.url !== originalApplication?.url) {
        const result = await stereoApplicationApi.update({
          stereoApplicationId: currentApplication.applicationId,
          instanceId: currentApplication.instanceId,
          url: currentApplication.url
        });
        if (result?.status === 204) {
          toast.success(tradInstanceToasterUpdate);
          setReloadData(!reloadData);
        }
      } else if (currentApplication?.url !== originalApplication?.url && originalApplication?.url === '') {
        const result = await stereoApplicationApi.create({
          instanceId: currentApplication.instanceId,
          url: currentApplication.url,
          type: currentApplication.type
        });
        if (result?.status === 200) {
          toast.success(tradInstanceToasterCreate);
          setReloadData(!reloadData);
        }
      }
    }
    if (currentApplication.url === '' && originalApplication?.url !== '') {
      const result = await stereoApplicationApi.delete({
        stereoApplicationId: currentApplication.applicationId,
        instanceId: currentApplication.instanceId
      });
      if (result?.status === 204) {
        toast.success(tradInstanceToasterDelete);
        setReloadData(!reloadData);
      }
    }
  };
  const onSubmit = async () => {
    const result = await instanceApi.update(currentInstance);
    if (result?.status === 204) {
      currentInstance.applications.forEach((app) => {
        const originalApp =
          originalInstance?.applications.find((origApp) => origApp.type === app.type) ?? new StereoApplication();
        originalApp.type = app.type;
        updateApplication(app, originalApp);
      });
      toast.success(tradInstanceToasterinstanceUpdate);
    }
    setUpdate(false);
  };
  const onCancelForm = () => {
    setUpdate(!update);
    setReloadData(!reloadData);
    setKey(key + 1);
  };

  return (
    <Card className="card-table">
      <Card.Header className="p-4 d-flex justify-content-between align-items-center">
        <h4 className="card-heading">{tradInstanceLabelInfo}</h4>
      </Card.Header>
      <Card.Body className="justify-content-end my-1 mx-5">
        <Form>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2"> {tradInstanceLabelInstanceToken} :</Form.Label>
            </Col>
            <Col md={8}>
              <InputGroup>
                <Form.Control type="text" defaultValue={currentInstance.instanceToken} readOnly disabled></Form.Control>
                <Button className="fas fa-check-square" onClick={() => copy(currentInstance.instanceToken)}>
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2"> {tradInstanceLabelName} :</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Control
                type="text"
                value={currentInstance.name}
                onChange={(event) => onChangeInstanceName(event)}></Form.Control>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2"> {tradInstanceLabelType} :</Form.Label>
            </Col>
            <Col md={8}>
              <ButtonGroup defaultValue={currentInstance.type} onClick={(event) => onChangeInstanceType(event)}>
                <Button className={currentInstance.type === 'SAAS' ? 'active' : ''} variant="secondary" value="SAAS">
                  {tradInstanceButtonSaas}
                </Button>
                <Button className={currentInstance.type === 'ONPREMISE' ? 'active' : ''} variant="secondary" value="ONPREMISE">
                  {tradInstanceButtonOnPremise}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2"> {tradInstanceLabelState} :</Form.Label>
            </Col>
            <Col md={8}>
              <ButtonGroup defaultValue={currentInstance.state} onClick={(event) => onChangeInstanceState(event)}>
                <Button className={currentInstance.state === 'STARTED' ? 'active' : ''} variant="secondary" value={'STARTED'}>
                  {tradInstanceButtonStart}
                </Button>
                <Button className={currentInstance.state === 'STOPPED' ? 'active' : ''} variant="secondary" value={'STOPPED'}>
                  {tradInstanceButtonStop}
                </Button>
                <Button className={currentInstance.state === 'ARCHIVED' ? 'active' : ''} variant="secondary" value={'ARCHIVED'}>
                  {tradInstanceButtonArchived}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2"> {tradInstanceLabelEnvironment} :</Form.Label>
            </Col>
            <Col md={8}>
              <ButtonGroup defaultValue={currentInstance.environment} onClick={(event) => onChangeEnvironment(event)}>
                <Button
                  className={currentInstance.environment === Environment.Demonstration ? 'active' : ''}
                  variant="secondary"
                  value={'Demonstration'}>
                  {tradInstanceButtonDemo}
                </Button>
                <Button
                  className={currentInstance.environment === Environment.Integration ? 'active' : ''}
                  variant="secondary"
                  value={'Integration'}>
                  {tradInstanceButtonIntegration}
                </Button>
                <Button
                  className={currentInstance.environment === Environment.Preproduction ? 'active' : ''}
                  variant="secondary"
                  value={'PreProduction'}>
                  {tradInstanceButtonPreProd}
                </Button>
                <Button
                  className={currentInstance.environment === Environment.Production ? 'active' : ''}
                  variant="secondary"
                  value={'Production'}>
                  {tradInstanceButtonProd}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2"> {tradInstanceUrlTeiaCore} :</Form.Label>
            </Col>
            <Col md={8}>
              <InputGroup className="mb-3">
                <div key={key}>
                  <Form.Control
                    type="text"
                    defaultValue={originalInstance?.applications?.find((app) => app.type === 1)?.url}
                    onChange={(event) => onChangeTeiaCoreUrl(event)}
                    placeholder="https://...."></Form.Control>
                </div>
                <InputGroup.Text
                  onClick={() => {
                    window.open(currentInstance?.applications?.find((app) => app.type === 1)?.url);
                  }}
                  style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faExternalLink} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2"> {tradInstanceUrlTeiaTwin} :</Form.Label>
            </Col>
            <Col md={8}>
              <InputGroup className="mb-3">
                <div key={key + 2}>
                  <Form.Control
                    className="d-inline"
                    type="text"
                    defaultValue={originalInstance?.applications?.find((app) => app.type === 2)?.url}
                    onChange={(event) => {
                      onChangeDigitalTwinUrl(event);
                    }}
                    placeholder="https://...."></Form.Control>
                </div>
                <InputGroup.Text
                  onClick={() => {
                    window.open(application.find((app) => app.type === 2)?.url);
                  }}
                  style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faExternalLink} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2"> {tradInstanceUrlTeiaEngine} :</Form.Label>
            </Col>
            <Col md={8}>
              <InputGroup className="mb-3">
                <div key={key + 3}>
                  <Form.Control
                    className="d-inline"
                    type="text"
                    defaultValue={originalInstance?.applications?.find((app) => app.type === 3)?.url}
                    onChange={(event) => onChangeTeiaEngineUrl(event)}
                    placeholder="https://...."></Form.Control>
                </div>
                <InputGroup.Text
                  onClick={() => {
                    window.open(currentInstance?.applications?.find((app) => app.type === 3)?.url);
                  }}
                  style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faExternalLink} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2">{tradInstanceUrlTeiaConnector} :</Form.Label>
            </Col>
            <Col md={8}>
              <InputGroup className="mb-3">
                <div key={key + 4}>
                  <Form.Control
                    className="d-inline"
                    type="text"
                    defaultValue={originalInstance?.applications?.find((app) => app.type === 4)?.url}
                    onChange={(event) => onChangeTeiaConnectorUrl(event)}
                    placeholder="https://...."></Form.Control>
                </div>
                <InputGroup.Text
                  onClick={() => {
                    window.open(currentInstance?.applications?.find((app) => app.type === 4)?.url);
                  }}
                  style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faExternalLink} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label className="mx-2">{tradInstanceUrlTeiaWorkflow} :</Form.Label>
            </Col>
            <Col md={8}>
              <InputGroup className="mb-3">
                <div key={key + 5}>
                  <Form.Control
                    className="d-inline"
                    type="text"
                    defaultValue={originalInstance?.applications?.find((app) => app.type === 5)?.url}
                    onChange={(event) => onChangeTeiaWorkflowUrl(event)}
                    placeholder="https://...."></Form.Control>
                </div>
                <InputGroup.Text
                  onClick={() => {
                    window.open(currentInstance?.applications?.find((app) => app.type === 5)?.url);
                  }}
                  style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faExternalLink} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="mx-2"> {tradInstanceLabelComments} :</Form.Label>
            </Col>
            <Col md={8}>
              <Form.Control
                as="textarea"
                value={currentInstance.comment}
                onChange={(event) => onChangeComment(event)}></Form.Control>
            </Col>
          </Row>
        </Form>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        {update ? (
          <>
            <Button className="mx-3" variant="secondary" onClick={onCancelForm}>
              {tradInstanceButtonCancel}
            </Button>
            <Button onClick={() => onSubmit()}>{tradInstanceButtonSave}</Button>
          </>
        ) : (
          <></>
        )}
      </Card.Footer>
    </Card>
  );
};

export default InstanceCard;
