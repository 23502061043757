import React from 'react';
import { Toaster as ReactHotToaster } from 'react-hot-toast';

export const Toaster = () => {
  return (
    <React.Fragment>
      <ReactHotToaster position="top-right" toastOptions={{ removeDelay: 500 }} />
    </React.Fragment>
  );
};
