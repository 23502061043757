import React, { FC } from 'react';
import { licenseApi } from '../../../api/licenseApi';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { License } from '../../../interface/License/License';
import { tradToaster, tradDelete } from './LicenseKeyTranslation';
import toast from 'react-hot-toast';

interface LicenseFormProps {
  show: boolean;
  setShow: Function;
  license: License;
  refreshData: boolean;
  setRefreshData: Function;
}

const DeleteLicenseModal: FC<LicenseFormProps> = (props) => {
  const { tradToasterSuccessDelete } = tradToaster();
  const { tradDeleteCancel, tradDeleteConfirm, tradDeleteTitle, tradDeleteDelete } = tradDelete();

  const { show, setShow, license, refreshData, setRefreshData } = props;

  const deleteLicense = async () => {
    const result = await licenseApi.delete(license);
    if (result?.status === 204) {
      toast.success(tradToasterSuccessDelete);
      setRefreshData(!refreshData);
    }
    setShow(false);
  };
  return (
    <CustomModal
      show={show}
      setShow={setShow}
      onAction={deleteLicense}
      cancelButton={tradDeleteCancel}
      actionName={tradDeleteConfirm}
      type="danger"
      title={tradDeleteTitle}>
      {tradDeleteDelete}
    </CustomModal>
  );
};

export default DeleteLicenseModal;
