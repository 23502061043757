import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import React, { FC } from 'react';
import { delegationAPi } from '../../../api/delegationApi';
import { tradToaster, tradDelete } from './DelegationkeyTranslation';
import toast from 'react-hot-toast';

interface DeleteDelegationProps {
  instanceId: number;
  delegationId: number;
  show: boolean;
  setShow: Function;
  refreshDelegations: boolean;
  setRefreshDelegations: Function;
}
const DeleteDelegationModal: FC<DeleteDelegationProps> = (props) => {
  const { tradToasterDelegationDelete } = tradToaster();
  const { tradDeleteCancel, tradDeleteAction, tradDeleteTitle, tradinstanceDeleteMessage } = tradDelete();
  const { show, setShow, instanceId, delegationId, refreshDelegations, setRefreshDelegations } = props;

  const deleteAccount = async () => {
    const result = await delegationAPi.delete(instanceId, delegationId);
    if (result?.status === 204) {
      toast.success(tradToasterDelegationDelete);
    }
    setRefreshDelegations(!refreshDelegations);
    setShow(false);
  };

  return (
    <CustomModal
      show={show}
      setShow={setShow}
      onAction={deleteAccount}
      cancelButton={tradDeleteCancel}
      actionName={tradDeleteAction}
      type="danger"
      title={tradDeleteTitle}>
      {tradinstanceDeleteMessage}
    </CustomModal>
  );
};

export default DeleteDelegationModal;
