import { AxiosError, AxiosResponse } from 'axios';
import React, { FC, useEffect, useRef } from 'react';

import customAxios from '../../middleware/requestInterceptor';
import { tradErrorToaster } from './ErrorInterceptorKeyTranslation';
import { t } from 'i18next';
import toast from 'react-hot-toast';

interface ErrorState {
  message: string;
  timestamp: number;
}

const ErrorInterceptor: FC = () => {
  const { tradErrorToasterError } = tradErrorToaster();
  const lastErrorRef = useRef<ErrorState | null>(null);

  const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  useEffect(() => {
    const onResponseError = (error: any): Promise<AxiosError> => {
      const { response } = error;

      const hasErrorMessageValidations = response?.data?.validations?.length > 0;

      const getErrorMessage = () => {
        if (hasErrorMessageValidations) {
          return response?.data?.validations?.[0];
        } else if (response?.data?.title) {
          return response?.data?.title;
        }

        return t('api.error.unknownError');
      };

      const errorMessage = getErrorMessage();
      const shouldShowToast = !lastErrorRef.current || lastErrorRef.current.message !== errorMessage;

      if (shouldShowToast) {
        toast.error(errorMessage, { id: errorMessage });
      }

      if (response?.status === 401 || response?.status === 403) {
        window.location.href = `${process.env.REACT_APP_BASE_URL}/forbidden`;
      }

      return Promise.reject(error);
    };

    const interceptor = customAxios.interceptors.response.use(onResponse, onResponseError);

    return () => {
      customAxios.interceptors.response.eject(interceptor);
    };
  }, [tradErrorToasterError]);

  return <></>;
};

export default ErrorInterceptor;
