import { t } from 'i18next';
import toast from 'react-hot-toast';

type CopyFn = (text: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): [CopyFn] {
  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      toast.error(t('instance.clipboard.error_navigator'));
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      toast.success(t('instance.clipboard.success'));
      return true;
    } catch (error) {
      toast.error(t('instance.clipboard.error'));
      return false;
    }
  };

  return [copy];
}

export default useCopyToClipboard;
