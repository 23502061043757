import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Button, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import { clientIdentityApi } from '../../../api/clientIdentityApi';
import { ClientIdentity, ClientConfiguration, IClientIdentityApi } from '../../../interface/ClientIdentity/clientIdentity';
import { handleAddInputs, handleChange, handleChangeSecretsDate, handleRemoveInputs } from '../../../utils/clientIdentityUtils';
import Select, { OnChangeValue } from 'react-select';
import { createOption } from '../../../utils/reactSelectUtils';
import { Guid } from '../../../models/guid';
import useCopyToClipboard from '../../../hooks/CopyToClipboard';
import { Option } from '../../../interface/option';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faCopy, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { tradClientIdentityEdition, tradClientIdentityForm, tradToaster, tradIcons } from './ClientIdentityKeyTranslation';
import { auto } from '@popperjs/core';
import toast from 'react-hot-toast';

interface ClientEditionModalProps {
  setShow: Function;
  show: boolean;
  source?: IClientIdentityApi;
  fetchClientIdentity: Function;
  instanceId: number;
  configurationOpenId: ClientConfiguration;
  instanceToken: string;
}

const typeOptions: Array<Option> = [];
const scopesOptions: Array<Option> = [];

const ClientEditionModal: React.FC<ClientEditionModalProps> = ({
  show,
  setShow,
  source,
  fetchClientIdentity,
  instanceId,
  configurationOpenId,
  instanceToken
}) => {
  const {
    tradClientIdentityFormName,
    tradClientIdentityFormId,
    tradClientIdentityFormRedirectUrl,
    tradClientIdentityFormPostRedirectUrl,
    tradClientIdentityFormAllowedCors,
    tradClientIdentityFormAllowedGrant,
    tradClientIdentityFormAllowedScopes,
    tradClientIdentityFormSecrets
  } = tradClientIdentityForm();
  const {
    tradClientIdentityEditionUpdateClient,
    tradClientIdentityEditionAddClient,
    tradClientIdentityEditionCancel,
    tradClientIdentityEditionNameFeedback,
    tradClientIdentityEditionIdFeedback,
    tradClientIdentityEditionEnable,
    tradClientIdentityEditionDisable
  } = tradClientIdentityEdition();
  const { tradToasterPatchMessage, tradToasterPostMessage, tradToasterSecretMessage } = tradToaster();
  const { tradIconsDelete, tradIconsAdd, tradIconsCopy } = tradIcons();

  const [clientFormData, setClientFormData] = useState<ClientIdentity>(new ClientIdentity());
  const [validated, setValidated] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const formRefPostLogout = useRef<HTMLInputElement>(null);
  const formRefRedirect = useRef<HTMLInputElement>(null);
  const formRefAllowedCorsOrigins = useRef<HTMLInputElement>(null);

  const [copy] = useCopyToClipboard();

  useEffect(() => {
    setClientFormData(new ClientIdentity(source));
  }, [source]);

  useEffect(() => {
    typeOptions.splice(0, typeOptions.length);
    scopesOptions.splice(0, scopesOptions.length);
    if (configurationOpenId) {
      configurationOpenId.grant_types_supported.forEach((grantType: string) => {
        if (grantType === 'authorization_code' || grantType === 'client_credentials') {
          typeOptions.push(createOption(grantType, grantType));
        }
      });
      configurationOpenId.scopes_supported.forEach((grantType: string) => {
        scopesOptions.push(createOption(grantType, grantType));
      });
    }
  }, [configurationOpenId]);

  useEffect(() => {
    if (show === true && source === undefined) {
      toast(tradToasterSecretMessage, { icon: <FontAwesomeIcon icon={faInfoCircle} className="text-primary" /> });
    }

    if (show === false && source === undefined) {
      setClientFormData(new ClientIdentity());
    }
    setValidated(false);
  }, [show]);

  const handleChangeGrantType = (newValue: OnChangeValue<Option, true>) => {
    setClientFormData({ ...clientFormData, allowedGrantTypes: newValue.map((value) => value.value) });
  };

  const handleChangeScopes = (newValue: OnChangeValue<Option, true>) => {
    setClientFormData({ ...clientFormData, allowedScopes: newValue.map((value) => value.value) });
  };

  function handleInputValidation(addedValue: string, formRef: RefObject<HTMLInputElement>) {
    if (formRef.current?.value) {
      setClientFormData(handleAddInputs(clientFormData, addedValue, formRef.current.value));
      formRef.current.value = '';
    }
  }

  const getFormUrl = () => {
    if (formRefPostLogout.current) {
      handleInputValidation('postLogoutRedirectUris', formRefPostLogout);
    }
    if (formRefRedirect.current) {
      handleInputValidation('redirectUris', formRefRedirect);
    }
    if (formRefAllowedCorsOrigins.current) {
      handleInputValidation('allowedCorsOrigins', formRefAllowedCorsOrigins);
    }
  };

  const handleSubmit = () => {
    const form = formRef.current;
    getFormUrl();
    if (source) {
      if (form && form.checkValidity()) {
        clientIdentityApi.patch(instanceId, source.configurationClientId, clientFormData).then((result) => {
          if (result.status === 204) {
            toast.success(tradToasterPatchMessage);
            fetchClientIdentity();
            setShow(false);
          }
        });
        setValidated(false);
      } else {
        setValidated(true);
      }
    } else {
      if (form && form.checkValidity()) {
        clientIdentityApi
          .post(instanceId, {
            ...clientFormData,
            properties: [
              {
                ...clientFormData.properties[0],
                value: instanceToken
              }
            ]
          })
          .then((result) => {
            if (result.status === 200) {
              toast.success(tradToasterPostMessage);
              fetchClientIdentity();
              setShow(false);
            }
          });
        setValidated(false);
      } else {
        setValidated(true);
      }
    }
  };

  const handleInput = (formRefPostLogout: React.RefObject<HTMLInputElement>, addedValue: string) => {
    return () => {
      if (formRefPostLogout.current?.value) {
        setClientFormData(handleAddInputs(clientFormData, addedValue, formRefPostLogout.current.value));
        formRefPostLogout.current.value = '';
      }
    };
  };

  const deleteButton = (
    setClientFormData: React.Dispatch<React.SetStateAction<ClientIdentity>>,
    clientFormData: ClientIdentity,
    index: number,
    action: string
  ) => {
    return (
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={<Tooltip>{tradIconsDelete}</Tooltip>}>
        <Button
          aria-label={tradIconsDelete}
          variant={'outline-danger'}
          onClick={() => setClientFormData(handleRemoveInputs(clientFormData, action, index))}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </OverlayTrigger>
    );
  };

  const addButton = (handleInput: () => void) => {
    return (
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={<Tooltip>{tradIconsAdd}</Tooltip>}>
        <Button aria-label={tradIconsAdd} variant="outline-success" onClick={handleInput} style={{ marginLeft: auto }}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </OverlayTrigger>
    );
  };

  const copyButton = (toCopy: string) => {
    return (
      <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={<Tooltip>{tradIconsCopy}</Tooltip>}>
        <Button aria-label={tradIconsCopy} style={{ zIndex: 0 }} variant="outline-secondary" onClick={() => copy(toCopy)}>
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </OverlayTrigger>
    );
  };

  const secretButtons = (index: number) => {
    const addButtonIcon = <FontAwesomeIcon icon={faPlus} />;
    const deleteButtonIcon = <FontAwesomeIcon icon={faTrash} />;
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 250 }}
        overlay={<Tooltip>{clientFormData.secrets.length === index + 1 ? tradIconsAdd : tradIconsDelete}</Tooltip>}>
        <Button
          aria-label={clientFormData.secrets.length === index + 1 ? tradIconsAdd : tradIconsDelete}
          style={{ zIndex: 0 }}
          variant={clientFormData.secrets.length === index + 1 ? 'outline-success' : 'outline-danger'}
          onClick={() => {
            clientFormData.secrets.length === index + 1
              ? setClientFormData(handleAddInputs(clientFormData, 'secrets', { value: Guid.newGuid(), expiration: null }))
              : setClientFormData(handleRemoveInputs(clientFormData, 'secrets', index));
          }}>
          {clientFormData.secrets.length === index + 1 ? addButtonIcon : deleteButtonIcon}
        </Button>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <CustomModal
        show={show}
        setShow={setShow}
        onAction={handleSubmit}
        actionName={'Validation'}
        title={source ? tradClientIdentityEditionUpdateClient : tradClientIdentityEditionAddClient}
        type={'primary'}
        cancelButton={tradClientIdentityEditionCancel}
        size="lg">
        <Form ref={formRef} noValidate validated={validated}>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormName}</Form.Label>
            <Form.Control
              required
              value={clientFormData.clientName}
              type="text"
              onChange={(e) => {
                setClientFormData({
                  ...clientFormData,
                  clientName: e.target.value
                });
              }}
              placeholder={tradClientIdentityFormName}></Form.Control>
            <Form.Control.Feedback type="invalid">{tradClientIdentityEditionNameFeedback}</Form.Control.Feedback>
          </Form.Group>
          <Form.Label>{tradClientIdentityFormId}</Form.Label>
          <Form.Group className="mb-3">
            <Form.Control
              required
              value={clientFormData.clientId}
              type="text"
              onChange={(e) => {
                setClientFormData({
                  ...clientFormData,
                  clientId: e.target.value
                });
              }}
              placeholder={tradClientIdentityFormId}></Form.Control>
            <Form.Control.Feedback type="invalid">{tradClientIdentityEditionIdFeedback}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{clientFormData.enabled ? tradClientIdentityEditionEnable : tradClientIdentityEditionDisable}</Form.Label>
            <Form.Check
              className="d-inline ms-2"
              checked={clientFormData.enabled}
              onChange={() =>
                setClientFormData({
                  ...clientFormData,
                  enabled: !clientFormData.enabled
                })
              }></Form.Check>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormPostRedirectUrl}</Form.Label>
            <>
              {clientFormData.postLogoutRedirectUris.map((field, index) => (
                <InputGroup key={index} className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder={field !== '' ? field : 'https://****.teia-solution.com'}
                    value={field}
                    onChange={(event) =>
                      setClientFormData(handleChange(clientFormData, 'postLogoutRedirectUris', event.target.value, index))
                    }
                  />
                  {deleteButton(setClientFormData, clientFormData, index, 'postLogoutRedirectUris')}
                </InputGroup>
              ))}
              <InputGroup className="mb-2">
                <Form.Control type="text" placeholder={'https://****.teia-solution.com'} ref={formRefPostLogout} />
                {addButton(handleInput(formRefPostLogout, 'postLogoutRedirectUris'))}
              </InputGroup>
            </>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormRedirectUrl}</Form.Label>
            <>
              {clientFormData.redirectUris.map((field, index) => (
                <InputGroup key={`inputgroup_${index}`} className="mb-2">
                  <Form.Control
                    key={`redirect_${index}`}
                    type="text"
                    placeholder={field !== '' ? field : 'https://****.teia-solution.com/Users/Account/LogonCallback'}
                    value={field}
                    onChange={(event) =>
                      setClientFormData(handleChange(clientFormData, 'redirectUris', event.target.value, index))
                    }
                  />
                  {deleteButton(setClientFormData, clientFormData, index, 'redirectUris')}
                </InputGroup>
              ))}
              <InputGroup className="mb-2">
                <Form.Control
                  type="text"
                  placeholder={'https://****.teia-solution.com/Users/Account/LogonCallback'}
                  ref={formRefRedirect}
                />
                {addButton(handleInput(formRefRedirect, 'redirectUris'))}
              </InputGroup>
            </>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormAllowedCors}</Form.Label>
            <>
              {clientFormData.allowedCorsOrigins.map((field, index) => (
                <InputGroup key={index} className="mb-2">
                  <Form.Control
                    type="text"
                    value={field}
                    placeholder={field !== '' ? field : 'https://****.teia-solution.com'}
                    onChange={(event) =>
                      setClientFormData(handleChange(clientFormData, 'allowedCorsOrigins', event.target.value, index))
                    }
                  />
                  {deleteButton(setClientFormData, clientFormData, index, 'allowedCorsOrigins')}
                </InputGroup>
              ))}
              <InputGroup className="mb-2">
                <Form.Control type="text" placeholder={'https://****.teia-solution.com'} ref={formRefAllowedCorsOrigins} />
                {addButton(handleInput(formRefAllowedCorsOrigins, 'allowedCorsOrigins'))}
              </InputGroup>
            </>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormAllowedGrant}</Form.Label>
            <>
              <Select
                placeholder={tradClientIdentityFormAllowedGrant}
                value={typeOptions.filter((type) => clientFormData.allowedGrantTypes.includes(type.value))}
                options={typeOptions}
                isMulti
                onChange={handleChangeGrantType}
                className="basic-multi-select"
              />
            </>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradClientIdentityFormAllowedScopes}</Form.Label>
            <>
              <Select
                placeholder={tradClientIdentityFormAllowedScopes}
                value={scopesOptions.filter((type) => clientFormData.allowedScopes.includes(type.value))}
                options={scopesOptions}
                isMulti
                closeMenuOnSelect={false}
                onChange={handleChangeScopes}
                className="basic-multi-select"
              />
            </>
          </Form.Group>
          {!source ? (
            <Form.Group>
              <Form.Label>{tradClientIdentityFormSecrets}</Form.Label>
              <>
                {clientFormData.secrets.map((field, index) => (
                  <InputGroup className="mb-2" key={`secret_group_${index}`}>
                    <Form.Control type={source ? 'password' : 'text'} value={field.value} disabled></Form.Control>
                    {copyButton(field.value)}
                    <Form.Control
                      type="date"
                      style={{ zIndex: 0 }}
                      disabled={!!source}
                      placeholder={field.expiration?.toString() || 'dd/mm/yyyy'}
                      value={field.expiration ? field.expiration.toString().split('T')[0] : ''}
                      onChange={(event) => {
                        setClientFormData(handleChangeSecretsDate(clientFormData, event.target.value, index));
                      }}
                    />
                    {secretButtons(index)}
                  </InputGroup>
                ))}
              </>
            </Form.Group>
          ) : null}
        </Form>
      </CustomModal>
    </>
  );
};

export default ClientEditionModal;
