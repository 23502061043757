import React, { FC } from 'react';
import { CustomModal } from '@stereograph/storybook/dist/components/CustomModal/CustomModal';
import { userApi } from '../../../api/userApi';
import { Users } from '../../../interface/users';
import { Instance } from '../../../models/instance';
import { tradDelete, tradToaster } from './UserKeyTranslation';
import toast from 'react-hot-toast';

interface FormProps {
  show: boolean;
  setShow: Function;
  currentInstance: Instance;
  refreshUser: Function;
  user: Users;
  reloadData: boolean;
}

const DeleteUserModal: FC<FormProps> = (props) => {
  const { tradToasterSuccessDelete } = tradToaster();
  const { tradDeleteCancel, tradDeleteConfirm, tradDeleteTitle, tradDeleteDeleteConfirm } = tradDelete();

  const { show, setShow, currentInstance, refreshUser, user, reloadData } = props;

  const deleteUser = async () => {
    const deleteUser = await userApi.delete(currentInstance.instanceId, user.id);
    if (deleteUser?.status === 204) {
      toast.success(tradToasterSuccessDelete);
      refreshUser(!reloadData);
    }
    setShow(false);
  };
  return (
    <CustomModal
      show={show}
      setShow={setShow}
      onAction={deleteUser}
      cancelButton={tradDeleteCancel}
      actionName={tradDeleteConfirm}
      type="danger"
      title={tradDeleteTitle}>
      {tradDeleteDeleteConfirm}
    </CustomModal>
  );
};

export default DeleteUserModal;
