import React, { ChangeEvent, FC, MutableRefObject, useMemo, useState } from 'react';
import { DataTable, GenericFilter } from '@stereograph/storybook';
import { perPageOptions } from '../../../utils/files-utils';
import { clientIdentityApi } from '../../../api/clientIdentityApi';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { ClientIdentity, IClientIdentityApi } from '../../../interface/ClientIdentity/clientIdentity';
import { tradHeader, tradTable, tradToaster } from './ClientIdentityTableKeyTranslation';
import { enabledBadgeStyle, disabledBadgeStyle } from '../BadgesStyle';
import toast from 'react-hot-toast';

interface ClientIdentityTableProps {
  setShowClientModal: Function;
  innerRef: MutableRefObject<any>;
  fetchClientIdentity: Function;
  clientIdentity: Array<IClientIdentityApi>;
  setShowClientDetail: Function;
  instanceId: number;
}

const ClientIdentityTable: FC<ClientIdentityTableProps> = ({
  instanceId,
  setShowClientModal,
  innerRef,
  fetchClientIdentity,
  clientIdentity,
  setShowClientDetail
}) => {
  const { tradToasterDeleteSuccess } = tradToaster();
  const {
    tradHeaderClientName,
    tradHeaderClientId,
    tradHeaderEnabled,
    tradHeaderDisabled,
    tradHeaderRedirectUris,
    tradHeaderAllowedCorsOrigins,
    tradHeaderAllowedScopes,
    tradHeaderLabel
  } = tradHeader();
  const { tradTableSeeDetails, tradTableUpdate, tradTableDelete, tradTableSortPage, tradTabledate, tradTableSearch } =
    tradTable();

  const [filterValue, setFilterValue] = useState('');
  const [filteredClientIdentity, setFilteredClientIdentity] = useState<IClientIdentityApi[]>([]);

  const deleteClientIdentity = (clientId: number) => {
    clientIdentityApi.delete(instanceId, clientId).then((result) => {
      if (result.status === 204) {
        toast.success(tradToasterDeleteSuccess);
        fetchClientIdentity();
      }
    });
  };

  const updateClientIdentity = (client: ClientIdentity) => {
    innerRef.current = client;
    setShowClientModal(true);
  };

  const openDetails = (client: ClientIdentity) => {
    innerRef.current = client;
    setShowClientDetail(true);
  };

  const handleFilter = (event: ChangeEvent) => {
    const inputValue = (event.target as HTMLInputElement).value;
    setFilterValue(inputValue);

    const filteredData = clientIdentity.filter((item) => item.clientName.toLowerCase().includes(inputValue.toLowerCase()));
    setFilteredClientIdentity(filteredData);
  };

  const columns = useMemo(
    () => [
      {
        Header: tradHeaderClientName,
        accessor: 'clientName',
        Cell: ({ cell }: CellProps<IClientIdentityApi>) => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>{cell.row.original.clientName}</Tooltip>}>
            <div
              className="text-truncate"
              style={{ maxWidth: '150px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.clientName}
            </div>
          </OverlayTrigger>
        )
      },
      {
        Header: tradHeaderClientId,
        accessor: 'clientId',
        Cell: ({ cell }: CellProps<IClientIdentityApi>) => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>{cell.row.original.clientId}</Tooltip>}>
            <div
              className="text-truncate"
              style={{ maxWidth: '150px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.clientId}
            </div>
          </OverlayTrigger>
        )
      },
      {
        Header: tradHeaderEnabled,
        accessor: 'enabled',
        Cell: ({ cell }: CellProps<boolean>) => {
          return (
            <span className={cell.value ? enabledBadgeStyle : disabledBadgeStyle}>
              {cell.value ? tradHeaderEnabled : tradHeaderDisabled}
            </span>
          );
        }
      },
      {
        Header: tradHeaderRedirectUris,
        accessor: 'redirectUris',
        Cell: ({ cell }: CellProps<string>) => {
          return (
            <>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 2500 }}
                overlay={<Tooltip>{cell.row.original.redirectUris}</Tooltip>}>
                <div
                  className="text-truncate"
                  style={{ maxWidth: '275px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {Array.isArray(cell.row.original.redirectUris) &&
                    cell.row.original.redirectUris.map((value, index) => {
                      return <div key={index}>{value}</div>;
                    })}
                </div>
              </OverlayTrigger>
            </>
          );
        }
      },
      {
        Header: tradHeaderAllowedCorsOrigins,
        accessor: 'allowedCorsOrigins',
        Cell: ({ cell }: CellProps<string>) => {
          return (
            <>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 2500 }}
                overlay={<Tooltip>{cell.row.original.allowedCorsOrigins}</Tooltip>}>
                <div
                  className="text-truncate"
                  style={{ maxWidth: '275px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {Array.isArray(cell.row.original.allowedCorsOrigins) &&
                    cell.row.original.allowedCorsOrigins.map((value, index) => {
                      return <div key={index}>{value}</div>;
                    })}
                </div>
              </OverlayTrigger>
            </>
          );
        }
      },
      {
        Header: tradHeaderAllowedScopes,
        accessor: 'allowedScopes',
        Cell: ({ cell }: CellProps<string>) => {
          return (
            <>
              {Array.isArray(cell.value) &&
                cell.value.map((value, index) => {
                  return <div key={index}>{value}</div>;
                })}
            </>
          );
        }
      },
      {
        Header: tradHeaderLabel,
        accessor: '',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<any>) => (
          <>
            <Dropdown>
              <Dropdown.Toggle variant="primary" size="sm">
                {tradHeaderLabel}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    openDetails(cell.row.original);
                  }}>
                  {tradTableSeeDetails}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    updateClientIdentity(cell.row.original);
                  }}>
                  {tradTableUpdate}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    deleteClientIdentity(cell.row.original.configurationClientId);
                  }}>
                  {tradTableDelete}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )
      }
    ],
    []
  );
  return (
    <DataTable
      columns={columns}
      items={filterValue ? filteredClientIdentity : clientIdentity}
      perPageOptions={perPageOptions}
      labelPage={tradTableSortPage}
      genericFilterValue={filterValue}
      lang={tradTabledate}>
      <GenericFilter placeholder={tradTableSearch} handleChange={handleFilter} />
    </DataTable>
  );
};

export default ClientIdentityTable;
