import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Button, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DataTable, GenericFilter } from '@stereograph/storybook';
import { Instance } from '../../../models/instance';
import CreateInstancesModal from '../../Modals/Instances/CreateInstancesModal';
import UpdateInstancesModal from '../../Modals/Instances/UpdateInstanceModal';
import { accountApi } from '../../../api/accountApi';
import { Account } from '../../../interface/account';
import DeleteInstanceModal from '../../Modals/Instances/DeleteInstanceModal';
import { StereoApplication } from '../../../models/stereoApplication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import useCopyToClipboard from '../../../hooks/CopyToClipboard';
import { perPageOptions } from '../../../utils/files-utils';
import { CellProps } from 'react-table';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { Option } from '../../../interface/option';
import { tradHeader, tradLabel, tradPlaceHolder, tradTable } from './InstanceTableKeyTranslation';

import '../Table.css';
import './InstanceTable.css';
import { instanceApi } from '../../../api/instanceApi';
import {
  demoBadgeStyle,
  expiredBadgeStyle,
  onPremiseBadgeStyle,
  preProddBadgeStyle,
  proddBadgeStyle,
  saasBadgeStyle,
  sdkBadgeStyle,
  validBadgeStyle,
  archivedBadgeStyle
} from '../BadgesStyle';

interface InstancesTableProps {
  instances?: Array<Instance>;
  itemsPerPage?: number;
  setItemsPerPage: Function;
  refreshInstance: Function;
}

interface FilterInstances {
  search: string;
  accounts: string[];
  instance: string[];
  status: string[];
  accommodation: string[];
}

const InstancesTable: FC<InstancesTableProps> = (props) => {
  const { instances, refreshInstance } = props;
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Array<Account>>([]);
  const [originalInstance, setOriginalInstance] = useState<Instance | null>(null);
  const navigate = useNavigate();
  const [currentInstance, setCurrentInstance] = useState(new Instance());
  const [filteredInstances, setFilteredInstances] = useState<FilterInstances>({
    search: '',
    accounts: [],
    instance: [],
    status: [],
    accommodation: []
  });

  const {
    tradHeaderAccount,
    tradHeaderInstance,
    tradHeaderType,
    tradHeaderEnvironment,
    tradHeaderState,
    tradHeaderCreation,
    tradHeaderInstanceToken,
    tradHeaderCopyToken,
    tradHeaderLabel
  } = tradHeader();
  const { tradLabelSeeDetails, tradLabelUpdate, tradLabelDelete } = tradLabel();
  const { tradTableInstance, tradTableCsv, tradTableAddInstance, tradTableSortPage, tradTabledate, tradTableFilter } =
    tradTable();
  const {
    tradPlaceHolderInstanceToken,
    tradPlaceHolderAccount,
    tradPlaceHolderInstance,
    tradPlaceHolderHebType,
    tradPlaceHolderState
  } = tradPlaceHolder();
  const [copy] = useCopyToClipboard();

  const [step, setStep] = useState<1>(1);

  useEffect(() => {
    const fetchAccount = async () => {
      const result: Array<Account> = await accountApi.getAll();
      setAccounts(result);
    };
    fetchAccount();
  }, []);

  const deleteInstance = async (instance) => {
    setCurrentInstance(instance);
    setOriginalInstance(new Instance(instance));
    setShowDelete(true);
  };

  const updateInstance = async (instance) => {
    setCurrentInstance(instance);
    setOriginalInstance(new Instance(instance));
    setShowUpdate(true);
  };

  const goToInstanceDetails = (InstanceId: number) => {
    navigate(`${InstanceId}`);
    window.scrollTo(0, 0);
  };

  const onChangeInstanceName = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.name = value;
    setCurrentInstance(newInstance);
  };
  const onChangeAccount = (value) => {
    if (value !== 0) {
      const newInstance = Object.assign({}, currentInstance);
      newInstance.accountId = value;
      setCurrentInstance(newInstance);
    }
  };
  const onChangeInstanceType = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.type = value;
    setCurrentInstance(newInstance);
  };
  const onChangeInstanceState = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.state = value;
    setCurrentInstance(newInstance);
  };
  function changeTeiaUrl(
    newInstance: Instance,
    application: StereoApplication | undefined,
    type: number,
    event: string,
    setCurrentInstance: Function
  ) {
    if (application) {
      application.url = event;
    } else {
      application = new StereoApplication();
      application.url = event;
      application.instanceId = newInstance.instanceId;
      application.type = type;
      newInstance.applications.push(application);
    }
    setCurrentInstance(newInstance);
  }

  const onchangeTeiaUrl = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    const teia = newInstance.applications.find((app) => app.type === 1);
    changeTeiaUrl(newInstance, teia, 1, value, setCurrentInstance);
  };
  const onChangeDigitalTwinUrl = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    const digitalTwin = newInstance.applications.find((app) => app.type === 2);
    changeTeiaUrl(newInstance, digitalTwin, 2, value, setCurrentInstance);
  };
  const onChangeTeiaEngineUrl = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    const teiaEngine = newInstance.applications.find((app) => app.type === 3);
    changeTeiaUrl(newInstance, teiaEngine, 3, value, setCurrentInstance);
  };
  const onChangeTeiaConnectorUrl = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    const teiaConnector = newInstance.applications.find((app) => app.type === 4);
    changeTeiaUrl(newInstance, teiaConnector, 4, value, setCurrentInstance);
  };
  const onChangeTeiaWorkflowUrl = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    const teiaWorkflow = newInstance.applications.find((app) => app.type === 5);
    changeTeiaUrl(newInstance, teiaWorkflow, 5, value, setCurrentInstance);
  };

  const onChangeEnvironment = (value) => {
    const newInstance = Object.assign({}, currentInstance);
    newInstance.environment = value;
    setCurrentInstance(newInstance);
  };

  const handleFilter = (event: ChangeEvent) => {
    setFilteredInstances({ ...filteredInstances, search: (event.target as HTMLInputElement).value });
  };

  const columnsInstancesDefinition = useMemo(
    () => [
      {
        Header: tradHeaderAccount,
        accessor: 'accountName',
        Cell: ({ cell }: CellProps<Instance>) => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 300 }}
            overlay={<Tooltip>{cell.row.original.accountName}</Tooltip>}>
            <span
              className="text-truncate"
              style={{ maxWidth: '90px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {cell.row.original.accountName}
            </span>
          </OverlayTrigger>
        )
      },
      {
        Header: tradHeaderInstance,
        accessor: 'name',
        Cell: ({ cell }: CellProps<Instance>) => (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={<Tooltip>{cell.row.original.name}</Tooltip>}>
            <a
              className="text-truncate"
              style={{ maxWidth: '90px', display: 'inline-block', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              onClick={() => goToInstanceDetails(cell.row.original.instanceId)}>
              {cell.row.original.name}
            </a>
          </OverlayTrigger>
        )
      },
      {
        Header: tradHeaderType,
        accessor: 'type',
        Cell: ({ cell }: CellProps<Instance>) => {
          switch (cell.row.original.type) {
            case 'SAAS':
              return <span className={saasBadgeStyle}>{cell.row.original.type}</span>;
            case 'ONPREMISE':
              return <span className={onPremiseBadgeStyle}>{cell.row.original.type}</span>;
            default:
              break;
          }
        }
      },
      {
        Header: tradHeaderEnvironment,
        accessor: 'environment',
        Cell: ({ cell }: CellProps<Instance>) => {
          switch (cell.row.original.environment) {
            case 'Demonstration':
              return <span className={demoBadgeStyle}>{cell.row.original.environment}</span>;
            case 'Integration':
              return <span className={sdkBadgeStyle}>{cell.row.original.environment}</span>;
            case 'PreProduction':
              return <span className={preProddBadgeStyle}>{cell.row.original.environment}</span>;
            case 'Production':
              return <span className={proddBadgeStyle}>{cell.row.original.environment}</span>;
            default:
              break;
          }
        }
      },
      {
        Header: tradHeaderState,
        accessor: 'state',
        Cell: ({ cell }: CellProps<Instance>) => {
          switch (cell.row.original.state) {
            case 'STARTED':
              return <span className={validBadgeStyle}>{cell.row.original.state}</span>;
            case 'STOPPED':
              return <span className={expiredBadgeStyle}>{cell.row.original.state}</span>;
            case 'ARCHIVED':
              return <span className={archivedBadgeStyle}>{cell.row.original.state}</span>;
            default:
              return null;
          }
        }
      },
      {
        Header: tradHeaderCreation,
        accessor: 'createdLocaleDate',
        Cell: ({ cell }: CellProps<Instance>) => {
          return <span>{cell.row.original.createdLocaleDate.split(',')[0]}</span>;
        }
      },
      {
        Header: tradHeaderInstanceToken,
        accessor: 'instanceToken',
        Cell: ({ cell }: CellProps<Instance>) => {
          return (
            <div className="d-flex">
              <Button
                className="fas fa-check-square mx-3 px-2"
                title={tradHeaderCopyToken}
                onClick={() => copy(cell.row.original.instanceToken)}>
                <FontAwesomeIcon icon={faCopy} />
              </Button>
              <p>{cell.row.original.instanceToken}</p>
            </div>
          );
        }
      },
      {
        Header: tradHeaderLabel,
        accessor: '',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<Instance>) => (
          <>
            <Dropdown>
              <Dropdown.Toggle variant="primary" size="sm">
                {tradHeaderLabel}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    goToInstanceDetails(cell.row.original.instanceId);
                  }}>
                  {tradLabelSeeDetails}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => updateInstance(cell.row.original)}>{tradLabelUpdate}</Dropdown.Item>
                <Dropdown.Item onClick={() => deleteInstance(cell.row.original)}>{tradLabelDelete}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )
      }
    ],
    [instances]
  );

  const onFilteredChangeCustom = (newValue: MultiValue<Option>, action: ActionMeta<Option>, accessor: string) => {
    if (action.action === 'select-option') {
      setFilteredInstances({ ...filteredInstances, [accessor]: newValue.map((value) => value.value) });
    }
    if (action.action === 'remove-value') {
      setFilteredInstances({ ...filteredInstances, [accessor]: newValue.map((value) => value.value) });
    }
  };

  const selectOption = (accessor: string) => {
    if (accessor === 'name' && filteredInstances.accounts.length) {
      const objUnique: Array<Option> = [];
      const filteredInstancesAccount = instances?.filter((instance) => filteredInstances.accounts.includes(instance.accountName));
      const setInstanceName = new Set<string>();
      filteredInstancesAccount?.forEach((t) => {
        if (!setInstanceName.has(t.name)) {
          setInstanceName.add(t.name);
          objUnique.push({ label: t.name, value: t.name });
        }
      });
      return objUnique.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }

    const option = instances
      ?.map((instance) => ({
        label: instance[accessor],
        value: instance[accessor]
      }))
      .filter((option, index, self) => index === self.findIndex((t) => t.label === option.label && t.value === option.value))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    return option;
  };

  const { search, instance, status, accommodation } = filteredInstances;

  const tableItemsFiltered = instances?.filter((item) => {
    return !(
      (search && !item.instanceToken.includes(search)) ||
      (filteredInstances.accounts.length && !filteredInstances.accounts.includes(item.accountName)) ||
      (instance.length && !instance.includes(item.name)) ||
      (status.length && !status.includes(item.state)) ||
      (accommodation.length && !accommodation.includes(item.type))
    );
  });

  return (
    <>
      <Card className="card-table mb-4">
        <Card.Header className="p-4 d-flex justify-content-between align-items-center">
          <h4 className="card-heading">{tradTableInstance}</h4>
          <div>
            <Button
              variant="outline-primary"
              onClick={() => {
                instanceApi.exportInstances();
              }}>
              {tradTableCsv}
            </Button>
            <span className="m-2" />
            <Button
              variant="outline-primary"
              onClick={() => {
                setShowCreate(true);
              }}>
              {tradTableAddInstance}
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="filter">
            <DataTable
              columns={columnsInstancesDefinition}
              items={tableItemsFiltered}
              labelPage={tradTableSortPage}
              lang={tradTabledate}
              perPageOptions={perPageOptions}>
              <h6>{tradTableFilter}</h6>
              <GenericFilter placeholder={tradPlaceHolderInstanceToken} handleChange={handleFilter} />
              <Select
                className="d-inline-block ms-3 w-196"
                placeholder={tradPlaceHolderAccount}
                options={selectOption('accountName')}
                isMulti
                isClearable={false}
                onChange={(newValue, action) => onFilteredChangeCustom(newValue, action, 'accounts')}
              />
              <Select
                className="d-inline-block ms-3 w-196"
                placeholder={tradPlaceHolderInstance}
                options={selectOption('name')}
                isMulti
                isClearable={false}
                onChange={(newValue, action) => onFilteredChangeCustom(newValue, action, 'instance')}
              />
              <Select
                className="d-inline-block ms-3 w-196"
                placeholder={tradPlaceHolderHebType}
                options={selectOption('type')}
                isMulti
                isClearable={false}
                onChange={(newValue, action) => onFilteredChangeCustom(newValue, action, 'accommodation')}
              />
              <Select
                className="d-inline-block ms-3 w-196"
                placeholder={tradPlaceHolderState}
                options={selectOption('state')}
                isMulti
                isClearable={false}
                onChange={(newValue, action) => onFilteredChangeCustom(newValue, action, 'status')}
              />
            </DataTable>
          </div>
        </Card.Body>
      </Card>
      <CreateInstancesModal
        step={step}
        setStep={setStep}
        show={showCreate}
        setShow={setShowCreate}
        refreshInstance={refreshInstance}
        accounts={accounts}
      />
      <UpdateInstancesModal
        originalInstance={originalInstance}
        currentInstance={currentInstance}
        step={step}
        setStep={setStep}
        show={showUpdate}
        setShow={setShowUpdate}
        refreshInstance={refreshInstance}
        accounts={accounts}
        changeAccount={onChangeAccount}
        changeInstanceName={onChangeInstanceName}
        changeDigitalTwinUrl={onChangeDigitalTwinUrl}
        changeTeiaEngineUrl={onChangeTeiaEngineUrl}
        changeInstanceState={onChangeInstanceState}
        changeInstanceType={onChangeInstanceType}
        changeTeiaUrl={onchangeTeiaUrl}
        changeTeiaConnectorUrl={onChangeTeiaConnectorUrl}
        changeTeiaWorkflowUrl={onChangeTeiaWorkflowUrl}
        onChangeEnvironment={onChangeEnvironment}
      />

      <DeleteInstanceModal
        show={showDelete}
        setShow={setShowDelete}
        refreshInstance={refreshInstance}
        currentInstance={currentInstance}
      />
    </>
  );
};

export default InstancesTable;
