/* eslint-disable no-unused-vars */
import { t } from 'i18next';
import { translatedText } from '../../../i18n/config';

export function tradToaster() {
  const tradToasterSuccess = translatedText('messages.success', t);
  const tradToasterSuccessDelete = translatedText('user.delete.success', t);
  const tradToasterRolesCreate = translatedText('roles.create.success', t);
  const tradToasterError = translatedText('messages.error', t);
  const tradToasterEmptyField = translatedText('user.update.error_empty_field', t);
  const tradToasterSuccessCreate = translatedText('user.create.success', t);
  return {
    tradToasterSuccess,
    tradToasterSuccessDelete,
    tradToasterRolesCreate,
    tradToasterError,
    tradToasterEmptyField,
    tradToasterSuccessCreate
  };
}

export function tradDelete() {
  const tradDeleteCancel = translatedText('cancel', t);
  const tradDeleteConfirm = translatedText('user.delete.confirm', t);
  const tradDeleteTitle = translatedText('Confirmation', t);
  const tradDeleteDeleteConfirm = translatedText('user.delete.confirmation_message', t);
  return { tradDeleteCancel, tradDeleteConfirm, tradDeleteTitle, tradDeleteDeleteConfirm };
}

export function tradEdition() {
  const tradEditionName = translatedText('user.name', t);
  const tradEditionEmail = translatedText('user.email', t);
  const tradEditionRole = translatedText('user.role', t);
  const tradEditionSelect = translatedText('user.select', t);
  const tradEditionRoleCreate = translatedText('user.roles.create', t);
  const tradEditionTitle = translatedText('user.add_new_user', t);
  const tradEditionCancel = translatedText('cancel', t);
  const tradEditionSuccess = translatedText('user.update.success', t);
  return {
    tradEditionName,
    tradEditionEmail,
    tradEditionRole,
    tradEditionSelect,
    tradEditionRoleCreate,
    tradEditionTitle,
    tradEditionCancel,
    tradEditionSuccess
  };
}
