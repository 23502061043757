import React, { useEffect, useState } from 'react';
import { faProjectDiagram, faFile, faUsers, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { instanceApi } from '../../api/instanceApi';
import { Instance } from '../../models/instance';
import { DashboardCounter } from '../../interface/DashboardCounter';
import { dashboardApi } from '../../api/dashboardApi';
import { LicenseDTO } from '../../interface/License/LicenseDTO';
import { licenseApi } from '../../api/licenseApi';
import DashboardInstanceCard from '../../components/Cards/DashboardInstanceCard';
import DashboardLicenseCard from '../../components/Cards/DashboardLicenseCard';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { tradDashboard } from './DashboardKeyTranslation';

const Dashboard = () => {
  const [instances, setInstances] = useState<Array<Instance>>([]);
  const [licenses, setLicenses] = useState<Array<LicenseDTO>>([]);
  const [dashboardData, setDashboardData] = useState<DashboardCounter>({
    nbAccounts: 0,
    nbInstances: 0,
    nbUser: 0
  });
  const { tradDashboardAccount, tradDashboardMenu, tradDashboardInstance, tradDashboardUsers } = tradDashboard();

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const result = await instanceApi.getAll();
        setInstances(result ?? []);
      } catch (err) {
        console.log(err);
      }
    };
    fetchInstances();
  }, []);

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const result = await dashboardApi.countAll();
        setDashboardData(result);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDashboard();
  }, []);
  useEffect(() => {
    const fetchLicenses = async () => {
      const result = await licenseApi.getAll();
      setLicenses(result ?? []);
    };
    fetchLicenses();
  }, []);
  const accountPill: any = {
    name: tradDashboardAccount,
    content: dashboardData.nbAccounts + ' ' + tradDashboardMenu
  };
  const instancePill: any = {
    name: tradDashboardInstance,
    content: dashboardData.nbInstances + ' ' + tradDashboardInstance
  };
  const userPill: any = {
    name: tradDashboardUsers,
    content: dashboardData.nbUser + ' ' + tradDashboardUsers
  };

  function DashboardCard(Pill: any, Icon: IconDefinition, IconColor: string) {
    return (
      <div className="st-flex st-justify-center st-items-center">
        <div className="st-relative st-overflow-hidden st-rounded-lg st-bg-white st-px-4 st-shadow sm:st-px-14 sm:st-pt-5 st-w-80 st-h-28">
          <dt>
            <div className={`st-fa-fw st-absolute st-rounded-md ${IconColor} st-p-3`}>
              <FontAwesomeIcon icon={Icon} className="text-white" />
            </div>
            <p className="st-ml-16 st-truncate st-text-base st-font-medium st-text-gray-500">{Pill.name}</p>
          </dt>
          <dd className="st-ml-16 st-flex st-items-baseline st-pb-4 sm:st-pb-5">
            <p className="st-text-xl st-font-semibold st-text-gray-900">{Pill.content}</p>
          </dd>
        </div>
      </div>
    );
  }
  return (
    <>
      <Container>
        <div className="st-flex st-justify-center st-items-center">
          <dl className="st-grid st-gap-5 sm:st-grid-cols-1 lg:st-grid-cols-3">
            {DashboardCard(accountPill, faProjectDiagram, 'st-bg-green-500')}
            {DashboardCard(instancePill, faFile, 'st-bg-indigo-500 st-pr-4 st-pl-4')}
            {DashboardCard(userPill, faUsers, 'st-bg-red-600')}
          </dl>
        </div>
      </Container>
      <DashboardLicenseCard
        className="col-md-8"
        licenses={licenses.filter((x) => x.licenseState === 'EXPIRED' || x.licenseState === 'EXPIREDSOON')}
      />
      <DashboardInstanceCard className="col-md-3" instances={instances.slice(0, instances.length > 10 ? 10 : instances.length)} />
    </>
  );
};

export default Dashboard;
