import { t } from 'i18next';
import React, { FC } from 'react';
import { Form, Button, ButtonGroup } from 'react-bootstrap';
import CustomModal from '../../CustomModal/CustomModal';
import { Account } from '../../../interface/account';
import { instanceApi } from '../../../api/instanceApi';
import { Environment, Instance } from '../../../models/instance';
import { StereoApplication } from '../../../models/stereoApplication';
import { stereoApplicationApi } from '../../../api/stereoApplicationApi';
import Select, { SingleValue } from 'react-select';
import UpdateUrlApplication from './CreateInstanceUrl/UpdateUrlApplication';
import { urlNames, tradToaster, tradUpdateForm, tradStateInstance } from './InstanceKeyTranslation';
import toast from 'react-hot-toast';

interface InstancesFormProps {
  show: boolean;
  setShow: Function;
  refreshInstance: Function;
  step: 1 | 2;
  setStep: Function;
  accounts: Array<Account>;
  currentInstance: Instance;
  originalInstance: Instance | null;
  changeInstanceName: Function;
  changeAccount: Function;
  changeInstanceType: Function;
  changeInstanceState: Function;
  changeTeiaUrl: Function;
  changeDigitalTwinUrl: Function;
  changeTeiaEngineUrl: Function;
  changeTeiaConnectorUrl: Function;
  changeTeiaWorkflowUrl: Function;
  onChangeEnvironment: (value: number) => void;
}

const instanceType: Array<{ label: string; value: string }> = [
  { label: t('instance.instance_type.demo'), value: Environment.Demonstration },
  { label: t('instance.instance_type.integration'), value: Environment.Integration },
  { label: t('instance.instance_type.preprod'), value: Environment.Preproduction },
  { label: t('instance.instance_type.production'), value: Environment.Production }
];

const UpdateInstancesModal: FC<InstancesFormProps> = (props) => {
  const {
    tradStateInstanceSaas,
    tradStateInstanceOnPremise,
    tradStateInstanceStarted,
    tradStateInstanceStopped,
    tradStateInstanceArchived
  } = tradStateInstance();
  const {
    tradToasterUpdateAppSuccess,
    tradToasterUpdateAppError,
    tradToasterCreationAppSuccess,
    tradToasterCreationAppError,
    tradToasterDeleteAppSuccess,
    tradToasterDeleteAppError,
    tradToasterUpdateSuccess
  } = tradToaster();
  const {
    tradUpdateFormNextStep,
    tradUpdateFormValidation,
    tradUpdateFormTitle,
    tradUpdateFormAddApp,
    tradUpdateFormCancelAdd,
    tradUpdateFormPreviousStep,
    tradUpdateFormChooseAccountName,
    tradUpdateFormChoose,
    tradUpdateFormInstanceName,
    tradUpdateFormHostingType,
    tradUpdateFormChooseState,
    tradUpdateFormChooseInstanceType
  } = tradUpdateForm();
  const {
    show,
    setShow,
    setStep,
    step,
    accounts,
    refreshInstance,
    originalInstance,
    currentInstance,
    changeInstanceName,
    changeAccount,
    changeInstanceType,
    changeInstanceState,
    changeTeiaUrl,
    changeDigitalTwinUrl,
    changeTeiaEngineUrl,
    changeTeiaConnectorUrl,
    changeTeiaWorkflowUrl,
    onChangeEnvironment
  } = props;

  const closeModal = () => {
    setStep(1);
    setShow(false);
  };
  const onChangeAccountName = (newValue: SingleValue<any>) => {
    if (newValue) {
      changeAccount(newValue.value);
    }
  };
  const onChangeInstance = (event, name) => {
    name(event.target.value);
  };

  const instanceTypeButton = instanceType.map((type) => {
    return (
      <Button
        className={currentInstance.environment === type.value ? 'active' : ''}
        value={type.value}
        key={type.value}
        variant="secondary"
        onClick={(event) => onChangeInstance(event, onChangeEnvironment)}>
        {type.label}
      </Button>
    );
  });

  const optionAccount = accounts?.map((account) => ({ label: account.name, value: account.id }));

  const updateApplication = async (currentApplication: StereoApplication, originalApplication: StereoApplication) => {
    if (currentApplication?.url !== '') {
      if (originalApplication?.url !== '' && currentApplication?.url !== originalApplication?.url) {
        const result = await stereoApplicationApi.update({
          stereoApplicationId: currentApplication.applicationId,
          instanceId: currentApplication.instanceId,
          url: currentApplication.url
        });
        if (result?.status === 204) {
          toast.success(tradToasterUpdateAppSuccess);
        } else if (!result) {
          toast.error(tradToasterUpdateAppError);
        }
        return result;
      } else if (currentApplication?.url !== originalApplication?.url) {
        const result = await stereoApplicationApi.create({
          instanceId: currentApplication.instanceId,
          url: currentApplication.url,
          type: currentApplication.type
        });
        if (result?.status === 200) {
          toast.success(tradToasterCreationAppSuccess);
        } else if (!result) {
          toast.error(tradToasterCreationAppError);
        }
        return result;
      }
    }

    if (currentApplication.url === '' && originalApplication?.url !== '') {
      const result = await stereoApplicationApi.delete({
        stereoApplicationId: currentApplication.applicationId,
        instanceId: currentApplication.instanceId
      });
      if (result?.status === 204) {
        toast.success(tradToasterDeleteAppSuccess);
      } else if (!result) {
        toast.error(tradToasterDeleteAppError);
      }
      return result;
    }
  };
  const onUpdateInstance = async (instance: Instance) => {
    const result = await instanceApi.update(instance);
    closeModal();
    if (result?.status === 204) {
      for (const app of currentInstance.applications) {
        const originalApp =
          originalInstance?.applications.find((origApp) => origApp.type === app.type) ?? new StereoApplication();
        originalApp.type = app.type;
        await updateApplication(app, originalApp);
      }
      toast.success(tradToasterUpdateSuccess);
    }
    refreshInstance();
  };

  function getFunctionName(name) {
    switch (name) {
      case urlNames.teiaCore:
        return changeTeiaUrl;
      case urlNames.teiaTwin:
        return changeDigitalTwinUrl;
      case urlNames.teiaEngine:
        return changeTeiaEngineUrl;
      case urlNames.teiaConnector:
        return changeTeiaConnectorUrl;
      case urlNames.teiaWorkflow:
        return changeTeiaWorkflowUrl;
      default:
        return null;
    }
  }

  return (
    <CustomModal
      show={show}
      setShow={setShow}
      onAction={() => (step === 1 ? setStep(2) : onUpdateInstance(currentInstance))}
      actionName={step === 1 ? tradUpdateFormNextStep : tradUpdateFormValidation}
      title={step === 1 ? step + '-' + tradUpdateFormTitle : step + '-' + tradUpdateFormAddApp}
      type="primary"
      cancelButton={step === 1 ? tradUpdateFormCancelAdd : tradUpdateFormPreviousStep}
      onActionCancel={step === 2 ? () => setStep(1) : () => closeModal()}>
      {step === 1 && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>{tradUpdateFormChooseAccountName}</Form.Label>
            <Select
              options={optionAccount}
              onChange={onChangeAccountName}
              placeholder={tradUpdateFormChoose}
              defaultValue={optionAccount.find((option) => option.value === currentInstance.accountId)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{tradUpdateFormInstanceName}</Form.Label>
            <Form.Control
              type="text"
              defaultValue={currentInstance.name}
              onChange={(event) => onChangeInstance(event, changeInstanceName)}></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3 position-relative z-0">
            <Form.Label className="d-block">{tradUpdateFormHostingType}</Form.Label>
            <ButtonGroup defaultValue={currentInstance.type} onClick={(event) => onChangeInstance(event, changeInstanceType)}>
              <Button className={currentInstance.type === 'SAAS' ? 'active' : ''} variant="secondary" value="SAAS">
                {tradStateInstanceSaas}
              </Button>
              <Button className={currentInstance.type === 'ONPREMISE' ? 'active' : ''} variant="secondary" value="ONPREMISE">
                {tradStateInstanceOnPremise}
              </Button>
            </ButtonGroup>
          </Form.Group>
          <Form.Group className="mb-3 position-relative z-0">
            <Form.Label className="d-block">{tradUpdateFormChooseState}</Form.Label>
            <ButtonGroup onClick={(event) => onChangeInstance(event, changeInstanceState)}>
              <Button className={currentInstance.state === 'STARTED' ? 'active' : ''} variant="secondary" value={'STARTED'}>
                {tradStateInstanceStarted}
              </Button>
              <Button className={currentInstance.state === 'STOPPED' ? 'active' : ''} variant="secondary" value={'STOPPED'}>
                {tradStateInstanceStopped}
              </Button>
              <Button className={currentInstance.state === 'ARCHIVED' ? 'active' : ''} variant="secondary" value={'ARCHIVED'}>
                {tradStateInstanceArchived}
              </Button>
            </ButtonGroup>
          </Form.Group>
          <Form.Group>
            <Form.Label className="d-block">{tradUpdateFormChooseInstanceType}</Form.Label>
            <ButtonGroup>{instanceTypeButton}</ButtonGroup>
          </Form.Group>
        </>
      )}
      {step === 2 &&
        [urlNames.teiaCore, urlNames.teiaTwin, urlNames.teiaEngine, urlNames.teiaConnector, urlNames.teiaWorkflow].map(
          (name, index) =>
            UpdateUrlApplication(t(`instance.name_app.${name}`), index + 1, currentInstance, (event) =>
              onChangeInstance(event, getFunctionName(name))
            )
        )}
    </CustomModal>
  );
};
export default UpdateInstancesModal;
